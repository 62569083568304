class _Accordion {
  constructor({
    accordionSelector = '.js-accordion', // Selector for accordions
    titleSelector = '.c-accordion__title', // Selector for accordion titles
    buttonClass = 'c-accordion__button', // Class for accordion buttons
    openClass = 'is-open', // Class for open accordion titles
  } = {}) {
    this.options = {
      titleSelector,
      buttonClass,
      openClass,
    };
    this.accordions = accordionSelector
      ? document.querySelectorAll(accordionSelector)
      : null;

    // Necessary so removeEventListener will work properly.
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  init() {
    if (this.accordions) {
      this.accordions.forEach((accordion, index) => {
        const titles = accordion.querySelectorAll(this.options.titleSelector);

        titles.forEach((title, i) => {
          // Create and set up each accordion button.
          const id = `accordion-${index}-${i}`;
          const button = document.createElement('button');
          button.classList.add(this.options.buttonClass);
          button.innerHTML = title.innerHTML;
          button.setAttribute('aria-controls', id);
          button.setAttribute('type', 'button');

          // Setup accordion content.
          const content = title.nextElementSibling;
          if (content) {
            content.setAttribute('id', id);
          }

          // Setup initial accordion states.
          const isOpen = title.classList.contains(this.options.openClass);
          if (isOpen) {
            this.open(button);
          } else {
            this.close(button);
          }

          // Insert accordion button.
          title.innerHTML = '';
          title.appendChild(button);
        });
      });
    }
  }

  close(button) {
    // Remove Event Listeners
    button.removeEventListener('click', () => this.close);

    // Update button and content attributes.
    const content = document.querySelector(
      `#${button.getAttribute('aria-controls')}`
    );
    button.setAttribute('aria-expanded', 'false');
    content.setAttribute('style', 'display: none;');
    content.setAttribute('aria-hidden', 'true');

    // Add event listeners.
    button.addEventListener('click', () => this.open(button));
  }

  open(button) {
    // Remove Event Listeners
    button.removeEventListener('click', () => this.open);

    // Update button and content attributes.
    const content = document.querySelector(
      `#${button.getAttribute('aria-controls')}`
    );
    button.setAttribute('aria-expanded', 'true');
    content.setAttribute('style', 'display: block;');
    content.setAttribute('aria-hidden', 'false');

    // Add event listeners.
    button.addEventListener('click', () => this.close(button));
  }
}

export default _Accordion;
